import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Box, Collapse, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'


const useStyles = (isTitleHanging = false) =>
makeStyles((theme) => ({
  root: {
    fontFamily: `'Cormorant Garamond', serif`,
    textAlign: 'center',
    width: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.between('xs', 'md')]: {
      minHeight: '60vh',
    },
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(6),
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '3rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    lineHeight: '.9',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      fontSize: theme.typography.pxToRem(20),
    },
  },
  title2: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1.5rem',
    lineHeight: 1.1,
    textAlign: 'left',
    fontWeight: 400,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      fontSize: theme.typography.pxToRem(17),
    },
  },
  enum1: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1rem',
    lineHeight: .9,
    textAlign: 'left',
    marginTop: theme.spacing(.5),
    marginBottom: theme.spacing(.1),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  enum2: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1rem',
    lineHeight: .9,
    textAlign: 'left',
    marginTop: theme.spacing(.5),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  description: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1rem',
    lineHeight: 1.1,
    textAlign: 'left',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(.8),
      fontSize: theme.typography.pxToRem(12),
    },
  },
  descriptionLight: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '.8rem',
    lineHeight: 1,
    textAlign: 'left',
    marginTop: theme.spacing(.5),
    marginBottom: theme.spacing(.5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(.2),
      marginBottom: theme.spacing(.2),
      fontSize: theme.typography.pxToRem(10),
    },
  },
  descriptionBold: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1.1rem',
    lineHeight: 1.1,
    textAlign: 'left',
    fontWeight: 600,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.6),
      marginBottom: theme.spacing(.9),
      fontSize: theme.typography.pxToRem(14),
    },
  },
  address: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1rem',
    lineHeight: 1.2,
    textAlign: 'left',
    marginTop: theme.spacing(.6),
    marginBottom: theme.spacing(.4),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  addressBloc: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

interface FriendsOfHouseProps {
  isTitleHanging?: boolean
  showBtn?: boolean
}


function Row(props: { type: string, row: any }) {
  const { row, type } = props;
  const [open, setOpen] = React.useState(true);
  const intl = useIntl()
  const classes = useStyles()()

  return (
    <React.Fragment>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography gutterBottom className={classes.descriptionBold} component="div">
                {intl.formatMessage({id: 'legalCookies.' + type + '.' + row.label + '.label'})}
                  <Typography gutterBottom className={classes.descriptionLight} component="div">
                  {row.label != 'kretz' &&
                    (<OutboundLink
                      href={intl.formatMessage({id: 'legalCookies.' + type + '.' + row.label + '.privacyPolicyTo'})}
                      target="_blank"
                      style={{ textDecoration: 'unset' }}
                    >
                      {intl.formatMessage({id: 'legalCookies.' + type + '.' + row.label + '.privacyPolicyText'})}
                    </OutboundLink>)
                  }
              </Typography>
              </Typography>
              
              <Table size="small" aria-label="cookies">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.descriptionBold} align="left">{intl.formatMessage({id: 'legalCookies.columns.label'})}</TableCell>
                    <TableCell className={classes.descriptionBold} align="left">{intl.formatMessage({id: 'legalCookies.columns.description'})}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.cookies.map((historyRow: any, index: any) => (
                    <TableRow key={historyRow}>
                      <TableCell className={classes.descriptionBold} style={{width: "165px"}} align="left">
                        {intl.formatMessage({ id: 'legalCookies.' + type + '.' + row.label + '.cookies.' + index + '.label'})}
                      </TableCell>
                      <TableCell style={{width: "auto"}} className={classes.description} align="left">
                        <div>{intl.formatMessage({ id: 'legalCookies.' + type + '.' + row.label + '.cookies.' + index + '.description'})}</div>
                        <div><span style={{fontWeight: 600}}>{intl.formatMessage({id: 'legalCookies.columns.validity'})}</span> : {intl.formatMessage({ id: 'legalCookies.' + type + '.' + row.label + '.cookies.' + index + '.validity'})}</div>
                        <div><span style={{fontWeight: 600}}>{intl.formatMessage({id: 'legalCookies.columns.referTo'})}</span> : {intl.formatMessage({ id: 'legalCookies.' + type + '.' + row.label + '.referTo'})}</div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const LegalCookiesTable: React.FC<any> = () => {
  const intl = useIntl()
  const classes = useStyles(false)()
  
  const cookies: any = {
    essentials: [
      {label: 'kretz', cookies: ["0"]}
    ], 
    thirdParty: [
      {label: 'vimeo', cookies: ["0","1","2"]},
      {label: 'flixel', cookies: ["0","1","2","3"]}
    ]
  }
  
  return (
    <>
      {["session", "essentials", "thirdParty"].map((type) => (
        <Grid item sm={12} className={classes.title2}>
          <TableContainer style={{maxWidth: "95vw"}} component={Paper}>
            <Table style={{borderBottom:"none"}} aria-label="collapsible table">
              <TableHead>
                <TableRow style={{borderBottom:"none"}}>
                  <TableCell style={{borderBottom:"none"}} className={classes.descriptionBold}>{intl.formatMessage({ id: 'legalCookies.' + type + '.title' })}</TableCell>
                </TableRow>
                <TableRow style={{borderBottom:"none"}}>
                  <TableCell style={{borderBottom:"none"}} className={classes.description}>{intl.formatMessage({ id: 'legalCookies.' + type + '.description' })}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody  style={{borderBottom:"none"}}>
                {cookies[type] && cookies[type].map((row: any) => (
                  <Row key={'cookies_' + type + '_' + row} row={row} type={type} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ))}
    </>
  )
}

export default LegalCookiesTable
