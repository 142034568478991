import React from 'react'
import Grid from '@material-ui/core/Grid'
import LegalCookiesTable from './LegalCookiesTable'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'

const useStyles = (isTitleHanging = false) =>
makeStyles((theme) => ({
  root: {
    fontFamily: `'Cormorant Garamond', serif`,
    textAlign: 'center',
    width: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.between('xs', 'md')]: {
      minHeight: '60vh',
    },
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(6),
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '3rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    lineHeight: '.9',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      fontSize: theme.typography.pxToRem(20),
    },
  },
  title2: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1.5rem',
    lineHeight: 1.1,
    textAlign: 'left',
    fontWeight: 400,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      fontSize: theme.typography.pxToRem(17),
    },
  },
  enum1: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1rem',
    lineHeight: .9,
    textAlign: 'left',
    marginTop: theme.spacing(.5),
    marginBottom: theme.spacing(.1),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  enum2: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1rem',
    lineHeight: .9,
    textAlign: 'left',
    marginTop: theme.spacing(.5),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  description: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1rem',
    lineHeight: 1.1,
    textAlign: 'left',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(.8),
      fontSize: theme.typography.pxToRem(12),
    },
  },
  descriptionBold: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1.1rem',
    lineHeight: 1.1,
    textAlign: 'left',
    fontWeight: 600,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.6),
      marginBottom: theme.spacing(.9),
      fontSize: theme.typography.pxToRem(14),
    },
  },
  address: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1rem',
    lineHeight: 1.2,
    textAlign: 'left',
    marginTop: theme.spacing(.6),
    marginBottom: theme.spacing(.4),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  addressBloc: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

export const LegalPolicy: React.FC<any> = () => {
  const intl = useIntl()
  const classes = useStyles(false)()
  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid item md={9} sm={10}>
        <Grid item sm={12} className={classes.title}>
          {intl.formatMessage({ id: 'link.legalPolicy' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_1' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_2' })}
        </Grid>
        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_1' })}
        </Grid>
        <Grid item sm={12} className={classes.descriptionBold}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_1_1' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_1_1' })}
        </Grid>
        <Grid item sm={12} className={classes.descriptionBold}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_1_2' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_1_2' })}
        </Grid>
        <Grid item sm={12} className={classes.descriptionBold}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_1_3' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_1_3' })}
        </Grid>
        <Grid item sm={12} className={classes.descriptionBold}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_1_4' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_1_4' })}
        </Grid>
        <Grid item sm={12} className={classes.descriptionBold}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_1_5' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_1_5' })}
        </Grid>
        <Grid item sm={12} className={classes.descriptionBold}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_1_6' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_1_6' })}
        </Grid>
        
        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_2' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_2_1' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_2_2' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_2_3' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_2_4' })}
        </Grid>

        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_3' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_3_1' })}
        </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.description_art_3_1_1' })}
          </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.description_art_3_1_2' })}
          </Grid>

        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_4' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>4.1</span><span>{' - ' + intl.formatMessage({ id: 'legalPolicy.description_art_4_1' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_4_1_1' })}
        </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.description_art_4_1_1_1' })}
          </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.description_art_4_1_1_2' })}
          </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.description_art_4_1_1_3' })}
          </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.description_art_4_1_1_4' })}
          </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.description_art_4_1_1_5' })}
          </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_4_1_2' })}
        </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.subtitle_art_4_1_2_1' })}
          </Grid>
            <Grid item sm={12} className={classes.enum2}>
              {intl.formatMessage({ id: 'legalPolicy.description_art_4_1_2_1_1' })}
            </Grid>
            <Grid item sm={12} className={classes.enum2}>
              {intl.formatMessage({ id: 'legalPolicy.description_art_4_1_2_2' })}
            </Grid>

          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.subtitle_art_4_1_2_2' })}
          </Grid>
            <Grid item sm={12} className={classes.enum2}>
              {intl.formatMessage({ id: 'legalPolicy.description_art_4_1_2_2_1' })}
            </Grid>

        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_4_1_3' })}
        </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.description_art_4_1_3_1' })}
          </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.description_art_4_1_3_2' })}
          </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>4.2</span><span>{' - ' + intl.formatMessage({ id: 'legalPolicy.title_art_4_2_1' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_4_2_2' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>4.3</span><span>{' - ' + intl.formatMessage({ id: 'legalPolicy.title_art_4_3' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>4.4</span><span>{' - ' + intl.formatMessage({ id: 'legalPolicy.title_art_4_4' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>4.5</span><span>{' - ' + intl.formatMessage({ id: 'legalPolicy.title_art_4_5' })}</span>
        </Grid>

        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_5' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>5.1</span><span>{' - ' + intl.formatMessage({ id: 'legalPolicy.title_art_5_1' })}</span>
        </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.description_art_5_1_1' })}
          </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.description_art_5_1_2' })}
          </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.description_art_5_1_3' })}
          </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.description_art_5_1_4' })}
          </Grid>

        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>5.2</span><span>{' - ' + intl.formatMessage({ id: 'legalPolicy.title_art_5_2' })}</span>
        </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.subtitle_art_5_2_1' })}
          </Grid>
            <Grid item sm={12} className={classes.enum2}>
              {intl.formatMessage({ id: 'legalPolicy.description_art_5_2_1_1' })}
            </Grid>
            <Grid item sm={12} className={classes.enum2}>
              {intl.formatMessage({ id: 'legalPolicy.description_art_5_2_1_2' })}
            </Grid>
            <Grid item sm={12} className={classes.enum2}>
              {intl.formatMessage({ id: 'legalPolicy.description_art_5_2_1_3' })}
            </Grid>
            <Grid item sm={12} className={classes.enum2}>
              {intl.formatMessage({ id: 'legalPolicy.description_art_5_2_1_4' })}
            </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.subtitle_art_5_2_2' })}
          </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.subtitle_art_5_2_3' })}
          </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.subtitle_art_5_2_4' })}
          </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.subtitle_art_5_2_5' })}
          </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.subtitle_art_5_2_6' })}
          </Grid>

        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_6' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>6.1</span><span>{' - ' + intl.formatMessage({ id: 'legalPolicy.title_art_6_1' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>6.2</span><span>{' - ' + intl.formatMessage({ id: 'legalPolicy.title_art_6_2_1' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_6_2_2' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_6_2_3' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_6_2_4' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_6_2_5' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_6_2_6' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_6_2_7' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_6_2_8' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_6_2_9' })}
        </Grid>

        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_7' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>7.1</span><span>{' - ' + intl.formatMessage({ id: 'legalPolicy.subtitle_art_7_1' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>7.2</span><span>{' - ' + intl.formatMessage({ id: 'legalPolicy.subtitle_art_7_2_1' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          { intl.formatMessage({ id: 'legalPolicy.subtitle_art_7_2_2' })}
        </Grid>
        <Grid item sm={12} className={classes.enum1}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_7_2_1' })}
        </Grid>
        <Grid item sm={12} className={classes.enum1}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_7_2_2' })}
        </Grid>
        <Grid item sm={12} className={classes.enum1}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_7_2_3' })}
        </Grid>
        <Grid item sm={12} className={classes.enum1}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_7_2_4' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>7.3</span><span>{' - ' + intl.formatMessage({ id: 'legalPolicy.subtitle_art_7_3' })}</span>
        </Grid>

        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_8' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.subtitle_art_8_1' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.subtitle_art_8_2' })}
        </Grid>

        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_9' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.subtitle_art_9_1' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.subtitle_art_9_2' })}
        </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.description_art_9_2_1' })}
          </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.description_art_9_2_2' })}
          </Grid>
          <Grid item sm={12} className={classes.enum1}>
            {intl.formatMessage({ id: 'legalPolicy.description_art_9_2_3' })}
          </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.subtitle_art_9_3' })}
        </Grid>

        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_10' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>10.1</span><span>{' - ' + intl.formatMessage({ id: 'legalPolicy.title_art_10_1' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_10_1_1' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_10_1_2' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>10.2</span><span>{' - ' + intl.formatMessage({ id: 'legalPolicy.title_art_10_2' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <LegalCookiesTable />
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>10.3</span><span>{' - ' + intl.formatMessage({ id: 'legalPolicy.title_art_10_3' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_10_3_1' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_10_3_2' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_10_3_3' })}
        </Grid>
          <Grid item sm={12} className={classes.enum1}>
            <OutboundLink
              href={intl.formatMessage({id: 'legalPolicy.description_art_10_3_3_1_to'})}
              target="_blank"
              style={{ textDecoration: 'unset' }}
            >
              {intl.formatMessage({id: 'legalPolicy.description_art_10_3_3_1_text'})}
            </OutboundLink>
          </Grid>
          <Grid item sm={12} className={classes.enum1}>
            <OutboundLink
              href={intl.formatMessage({id: 'legalPolicy.description_art_10_3_3_2_to'})}
              target="_blank"
              style={{ textDecoration: 'unset' }}
            >
              {intl.formatMessage({id: 'legalPolicy.description_art_10_3_3_2_text'})}
            </OutboundLink>
          </Grid>
          <Grid item sm={12} className={classes.enum1}>
            <OutboundLink
              href={intl.formatMessage({id: 'legalPolicy.description_art_10_3_3_3_to'})}
              target="_blank"
              style={{ textDecoration: 'unset' }}
            >
              {intl.formatMessage({id: 'legalPolicy.description_art_10_3_3_3_text'})}
            </OutboundLink>
          </Grid>
          <Grid item sm={12} className={classes.enum1}>
            <OutboundLink
              href={intl.formatMessage({id: 'legalPolicy.description_art_10_3_3_4_to'})}
              target="_blank"
              style={{ textDecoration: 'unset' }}
            >
              {intl.formatMessage({id: 'legalPolicy.description_art_10_3_3_4_text'})}
            </OutboundLink>
          </Grid>
          <Grid item sm={12} className={classes.enum1}>
            <OutboundLink
              href={intl.formatMessage({id: 'legalPolicy.description_art_10_3_3_5_to'})}
              target="_blank"
              style={{ textDecoration: 'unset' }}
            >
              {intl.formatMessage({id: 'legalPolicy.description_art_10_3_3_5_text'})}
            </OutboundLink>
          </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_10_3_4' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_10_3_5' })}
        </Grid>

        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_11' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>11.1</span><span>{' - ' + intl.formatMessage({ id: 'legalPolicy.description_art_11_1_1' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_11_1_2' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>11.2</span><span>{' - ' + intl.formatMessage({ id: 'legalPolicy.description_art_11_2_1' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_11_2_2' })}
        </Grid>

        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_12' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>12.1</span><span>{' - ' + intl.formatMessage({ id: 'legalPolicy.description_art_12_1_1' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_12_1_2' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_12_1_3' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_12_1_4' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_12_1_5' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>12.2</span><span>{' - ' + intl.formatMessage({ id: 'legalPolicy.description_art_12_2_1' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_12_2_2' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_12_2_3' })}
        </Grid>

        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'legalPolicy.title_art_13' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_13_1' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
            <Grid item sm={12} className={classes.enum1}>
              <OutboundLink
                href={intl.formatMessage({id: 'legalPolicy.netflix.privacyPolicyTo'})}
                target="_blank"
                style={{ textDecoration: 'unset' }}
              >
                {intl.formatMessage({id: 'legalPolicy.netflix.privacyPolicyText'})}
              </OutboundLink>
            </Grid>
            <Grid item sm={12} className={classes.enum1}>
              <OutboundLink
                href={intl.formatMessage({id: 'legalPolicy.youtube.privacyPolicyTo'})}
                target="_blank"
                style={{ textDecoration: 'unset' }}
              >
                {intl.formatMessage({id: 'legalPolicy.youtube.privacyPolicyText'})}
              </OutboundLink>
            </Grid>
            <Grid item sm={12} className={classes.enum1}>
              <OutboundLink
                href={intl.formatMessage({id: 'legalPolicy.instagram.privacyPolicyTo'})}
                target="_blank"
                style={{ textDecoration: 'unset' }}
              >
                {intl.formatMessage({id: 'legalPolicy.instagram.privacyPolicyText'})}
              </OutboundLink>
            </Grid>
            <Grid item sm={12} className={classes.enum1}>
              <OutboundLink
                href={intl.formatMessage({id: 'legalPolicy.facebook.privacyPolicyTo'})}
                target="_blank"
                style={{ textDecoration: 'unset' }}
              >
                {intl.formatMessage({id: 'legalPolicy.facebook.privacyPolicyText'})}
              </OutboundLink>
            </Grid>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_13_2' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalPolicy.description_art_13_3' })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LegalPolicy
